import { darken, lighten } from "@mui/material";

import * as styleConstants from "styles/constants";

/**
 * This function returnsthe customized Mui theme.
 * Specification at https://material-ui.com/customization/default-theme/.
 * @param {Object} constants The object with all the constants to incorporate and use to compute the theme.
 * @param {("light"|"dark")} mode The theme mode to display.
 * @return {Object} The computed theme to pass to the ThemeProvider.
 */
const theme = (constants, mode) => {
  const isDarkMode = mode === "dark" && constants.ALLOW_DARK_MODE;
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: constants.SIZE_WIDGET_WIDTH_SMALL,
        md: constants.SIZE_WIDGET_WIDTH_MEDIUM,
        lg: constants.SIZE_WIDGET_WIDTH_LARGE,
        xl: 1280,
      },
    },
    palette: {
      mode: isDarkMode ? "dark" : "light",
      // This palette color is for the main color that is mainly use by lot of components in the widget.
      primary: {
        main: constants.COLOR_PRIMARY,
        hover: constants.COLOR_BUTTON_BACKGROUND_CONTAINED_PRIMARY_HOVER
          || darken(constants.COLOR_PRIMARY, constants.OFFSET_COLOR_VARIANT_HOVER),
        disabled: lighten(constants.COLOR_PRIMARY, constants.OFFSET_COLOR_VARIANT_DISABLED),
        background: lighten(constants.COLOR_PRIMARY, constants.OFFSET_COLOR_VARIANT_BACKGROUND),
        shadow: lighten(constants.COLOR_PRIMARY, constants.OFFSET_COLOR_VARIANT_SHADOW),
      },
      // This palette color is for the restricted set of grey we use in the widget.
      grey: {
        grey1: isDarkMode ? constants.COLOR_GREY_1_DARK : constants.COLOR_GREY_1_LIGHT,
        grey2: isDarkMode ? constants.COLOR_GREY_2_DARK : constants.COLOR_GREY_2_LIGHT,
        grey3: isDarkMode ? constants.COLOR_GREY_3_DARK : constants.COLOR_GREY_3_LIGHT,
      },
      // This palette color is not used actually for now, may be later, or never. Do not mix with grey palette above.
      secondary: {
        main: isDarkMode ? constants.COLOR_GREY_3_DARK : constants.COLOR_GREY_3_LIGHT, // constants.COLOR_SECONDARY,
      },
      // This palette color is mainly for error messages
      error: {
        background: lighten(constants.COLOR_ERROR, constants.OFFSET_COLOR_VARIANT_BACKGROUND),
        main: constants.COLOR_ERROR,
      },
      // This palette color is mainly for warning messages
      warning: {
        background: lighten(constants.COLOR_WARNING, constants.OFFSET_COLOR_VARIANT_BACKGROUND),
        main: constants.COLOR_WARNING,
      },
      // This palette color is mainly for success messages
      success: {
        main: constants.COLOR_SUCCESS,
      },
      // This palette color is for button with the props 'color' that equals 'black'
      black: {
        main: isDarkMode ? styleConstants.COLOR_GENERIC_WHITE : styleConstants.COLOR_GENERIC_BLACK,
        contrastText: isDarkMode ? styleConstants.COLOR_GENERIC_BLACK : styleConstants.COLOR_GENERIC_WHITE,
      },
      // This palette color is customized
      white: {
        main: isDarkMode ? styleConstants.COLOR_GENERIC_BLACK : styleConstants.COLOR_GENERIC_WHITE,
        contrastText: isDarkMode ? styleConstants.COLOR_GENERIC_WHITE : styleConstants.COLOR_GENERIC_BLACK,
      },
      // The background of the main cards and 'bloc' components. They can inherit of this color.
      background: {
        default: isDarkMode ? styleConstants.COLOR_GENERIC_BLACK_DARK : styleConstants.COLOR_GENERIC_WHITE,
        inputBackground: isDarkMode ? styleConstants.COLOR_GENERIC_BLACK_DARK : styleConstants.COLOR_GENERIC_WHITE,
        paper: isDarkMode ? styleConstants.COLOR_GENERIC_BLACK_DARK : styleConstants.COLOR_GENERIC_WHITE,
      },
      // The palette color for the main text of the widgets
      text: {
        primary: isDarkMode ? constants.COLOR_TEXT_PRIMARY_DARK : constants.COLOR_TEXT_PRIMARY_LIGHT,
        secondary: isDarkMode
          ? constants.COLOR_TEXT_SECONDARY_DARK || darken(constants.COLOR_TEXT_PRIMARY_DARK, 0.2)
          : constants.COLOR_TEXT_SECONDARY_LIGHT || lighten(constants.COLOR_TEXT_PRIMARY_LIGHT, 0.5),
        grey: isDarkMode ? constants.COLOR_TEXT_GREY_DARK : constants.COLOR_TEXT_GREY_LIGHT,
      },
      // This palette color is for all colors that can be overridden by the organizer.
      customized: {
        cardBorder: constants.COLOR_CARD_BORDER,
        cardBackground: constants.COLOR_BACKGROUND_CARD,
        walletCardBackground: constants.COLOR_BACKGROUND_CARD_WALLET,
        walletBalanceCardBackground: constants.COLOR_BACKGROUND_CARD_WALLET_BALANCE,
        walletBalanceCardDetailBackground: constants.COLOR_BACKGROUND_CARD_WALLET_BALANCE_DETAIL,
        walletTicketChipCardDetailBackground: constants.COLOR_BACKGROUND_CARD_WALLET_TICKET_CHIP_DETAIL,
        widgetBackground: constants.COLOR_BACKGROUND_WIDGET,
        // This is the color of text that are displayed when the root background is transparent
        // (not in a div with background). Example: when in iframe in a dark website...
        // eslint-disable-next-line no-nested-ternary
        widgetTextWithNoBackground: constants.COLOR_TEXT_WITH_NO_BACKGROUND
          ? constants.COLOR_TEXT_WITH_NO_BACKGROUND
          : isDarkMode ? constants.COLOR_TEXT_PRIMARY_DARK : constants.COLOR_TEXT_PRIMARY_LIGHT,
        buttonOutlinedBackground: constants.COLOR_BUTTON_BACKGROUND_OUTLINED,
        historyCardBackGround: constants.DISPLAY_COLOR_BACKGROUND_HISTORY_CARD_TRANSPARENT
          ? "transparent"
          : null, // If null, then we fall back to the default card background defined by the component
        historyPositiveAmounts: constants.COLOR_HISTORY_POSITIVE_AMOUNTS || constants.COLOR_PRIMARY,
        historyPositiveAmountsBackground: constants.DISPLAY_COLOR_BACKGROUND_HISTORY_POSITIVE_AMOUNTS
          ? lighten(
            constants.COLOR_HISTORY_POSITIVE_AMOUNTS || constants.COLOR_PRIMARY,
            constants.OFFSET_COLOR_VARIANT_BACKGROUND,
          )
          : null,
        historyNegativeAmounts: constants.COLOR_HISTORY_NEGATIVE_AMOUNTS || constants.COLOR_ERROR,
        historyNegativeAmountsBackground: constants.DISPLAY_COLOR_BACKGROUND_HISTORY_NEGATIVE_AMOUNTS
          ? lighten(
            constants.COLOR_HISTORY_NEGATIVE_AMOUNTS || constants.COLOR_ERROR,
            constants.OFFSET_COLOR_VARIANT_BACKGROUND,
          )
          : null,
        historySplitPaymentWarning: constants.COLOR_SPLIT_PAYMENT_WARNING,
        loadingCardBackground1: constants.COLOR_BACKGROUND_CARD_LOADING_1,
        loadingCardBackground2: constants.COLOR_BACKGROUND_CARD_LOADING_2,
        link: constants.COLOR_LINK || constants.COLOR_PRIMARY,
        buttonLinkHover: constants.COLOR_BUTTON_LINK_HOVER || constants.COLOR_PRIMARY,
        rawReceiptBackground: constants.COLOR_BACKGROUND_RECEIPT,
      },
      offsetColorVariantBackground: constants.OFFSET_COLOR_VARIANT_BACKGROUND,
      offsetColorVariantDisabled: constants.OFFSET_COLOR_VARIANT_DISABLED,
      offsetColorVariantHover: constants.OFFSET_COLOR_VARIANT_HOVER,
      tonalOffset: 0.2,
      contrastThreshold: 3,
    },
    spacing: 5,
    typography: {
      fontFamily: constants.FONT_FAMILY,
      button: {
        textTransform: "none",
        lineHeight: "normal",
      },
      preorderCardTitle: {
        fontFamily: constants.FONT_FAMILY,
        fontSize: "1.125rem",
        fontWeight: 700,
        lineHeight: "normal",
        textTransform: "uppercase",
      },
      title1: {
        fontFamily: constants.FONT_FAMILY,
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "normal",
      },
      title1Primary: {
        fontFamily: constants.FONT_FAMILY,
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "normal",
        color: constants.COLOR_PRIMARY,
      },
      title1light: {
        fontFamily: constants.FONT_FAMILY,
        fontSize: "1rem",
        lineHeight: "normal",
      },
      title2: {
        fontFamily: constants.FONT_FAMILY,
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: "normal",
      },
      title2Primary: {
        fontFamily: constants.FONT_FAMILY,
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: "normal",
        color: constants.COLOR_PRIMARY,
      },
      body1: {
        fontFamily: constants.FONT_FAMILY,
        fontSize: "0.875rem",
        lineHeight: "normal",
      },
      body1Spaced: {
        fontFamily: constants.FONT_FAMILY,
        fontSize: "0.875rem",
        lineHeight: 1.4,
      },
      bold500: {
        fontWeight: 500,
      },
    },
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            lineHeight: "normal",
          },
          contained: {
            marginLeft: "0px",
            marginRight: "0px",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: "14px",
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          component: "p",
        },
        styleOverrides: {
          root: {
            color: isDarkMode ? styleConstants.COLOR_GENERIC_WHITE : styleConstants.COLOR_GENERIC_BLACK,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "font-face": constants.FONTS.map((font) => ({
            fontFamily: font.family,
            fontWeight: font.weight || "normal",
            fontDisplay: font.display || "swap",
            src: `url(${font.url}) format("${font.format}")`,
          })),
        },
      },
    },
    ...constants,
  };
};

export default theme;
