import React from "react";

import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import * as constants from "../constants";
import MessageContext from "./MessageContext";

/**
 * The context provider of MessageProvider
 */
const MessageContextProvider = ({ children, defaultClearingTimeout }) => {
  const [state, setState] = React.useState([]);

  const clear = React.useCallback(
    (id, immediately) => {
      if (id) {
        setState((previous) => (immediately
          ? previous.filter((m) => m.id !== id)
          : previous.map((m) => (m.id !== id ? m : { ...m, isRemoving: true }))));
      } else {
        setState((previous) => (immediately
          ? []
          : previous.map((m) => ({ ...m, isRemoving: true }))));
      }
    },
    [setState],
  );

  const clearFromType = React.useCallback(
    (type, immediately) => {
      if (type) {
        setState((previous) => (immediately
          ? previous.filter((m) => m.type !== type)
          : previous.map((m) => (m.type !== type ? m : { ...m, isRemoving: true }))));
      } else {
        setState([]);
      }
    },
    [setState],
  );

  const setMessageWithType = React.useCallback(
    (text, type, clearingTimeout, clearingImmediately) => {
      const id = uuidv4();
      setState((previous) => [...previous, { type, text, id, isRemoving: false }]);
      // If clearingTimeout == null, then use the defaultClearingTimeout.
      // If clearingTimeout == 0, then do not clear the message. Should be done manually.
      if (clearingTimeout || (defaultClearingTimeout && clearingTimeout !== 0)) {
        setTimeout(
          () => clear(id, clearingImmediately),
          clearingTimeout || defaultClearingTimeout,
        );
      }
      return id;
    },
    [defaultClearingTimeout, clear],
  );

  React.useEffect(() => {
    let timeout = null;
    if (state.some((m) => m.isRemoving)) {
      timeout = setTimeout(
        () => setState((previous) => previous.filter((m) => !m.isRemoving)),
        3000, // this is the maximal time before we definitely clear the message from the state
      );
    }
    return () => clearTimeout(timeout);
  }, [state]);

  const error = React.useCallback(
    (text, clearingTimeout, clearingImmediately) => (
      setMessageWithType(text, constants.TYPE_ERROR, clearingTimeout, clearingImmediately)
    ),
    [setMessageWithType],
  );

  const info = React.useCallback(
    (text, clearingTimeout, clearingImmediately) => (
      setMessageWithType(text, constants.TYPE_INFO, clearingTimeout, clearingImmediately)
    ),
    [setMessageWithType],
  );

  const success = React.useCallback(
    (text, clearingTimeout, clearingImmediately) => (
      setMessageWithType(text, constants.TYPE_SUCCESS, clearingTimeout, clearingImmediately)
    ),
    [setMessageWithType],
  );

  const warning = React.useCallback(
    (text, clearingTimeout, clearingImmediately) => (
      setMessageWithType(text, constants.TYPE_WARNING, clearingTimeout, clearingImmediately)
    ),
    [setMessageWithType],
  );

  const message = React.useCallback(
    (text, type, clearingTimeout, clearingImmediately) => (
      setMessageWithType(text, type, clearingTimeout, clearingImmediately)
    ),
    [setMessageWithType],
  );

  const value = {
    clear,
    clearFromType,
    messages: state,
    error,
    info,
    success,
    warning,
    message,
  };

  return (
    <MessageContext.Provider value={value}>
      {children}
    </MessageContext.Provider>
  );
};

MessageContextProvider.propTypes = {
  /**
   * The wrapped components that will have access to the context.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
  /**
   * The default timeout to use before clearing the message.
   */
  defaultClearingTimeout: PropTypes.number,
};

MessageContextProvider.defaultProps = {
  defaultClearingTimeout: null,
};

export default MessageContextProvider;
